<template>
  <div>
    <p>
      Upload your celebration photo(s) to complete the check-in
    </p>

    <div class="van-hairline--top-bottom">
      <van-uploader v-model="photos" multiple :max-count="3" />
    </div>

    <p>
      <van-button type="primary" @click="doJournal">Journal</van-button>
    </p>
  </div>
</template>

<script>
import BaseCheckIn from "@/components/Journal/BaseJournal.vue";

export default BaseCheckIn.extend({
  name: "PhotoCheckIn",
  data() {
    return {
      photos: []
    };
  }
});
</script>

<style scoped></style>
